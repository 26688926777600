body{
	font-family: sans-serif;
	
}
.App {
  text-align: center;
  /* background: ivory; */
  background: linear-gradient(117deg, rgba(234,235,214,1) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 85%, rgba(234,235,214,1) 100%);width:100%;
height: 100vh;
padding-top: 25vh;
box-sizing: border-box;
/* background: url(./img/building.jpg) no-repeat 200% 100%; */

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a{
	color: #131313;
	text-decoration: none;
	font-weight: 600;
}
.changingbg{
	position: absolute;
	width: 15vw;
	height: 15vw;
	background: url(./img/girl_coding.png) no-repeat 0% 0%;
	background-size: 100%;
	bottom: 30vh;
	left: 42.5vw;
}


ul{
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
li{
  padding: 1em;
}
h1, h6{
  margin-block-start: 0;
}
h6{
	margin-block-end: 0.5em;
	text-align: center;
}
.add_gap{
	margin-top: 40vh;
}




@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");
* {
  position: relative;
  box-sizing: border-box;
}

.iframely-embed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: white;
}

#app {
  z-index: 100;
}
/* #app:hover {
  opacity: 0.5;
} */

#app {
  display: grid;
  grid-template-columns: 2fr 2fr;
  align-items: center;
  padding-bottom: 4vmin;
  height: 90vh;
  width: 100%;
  background: rgb(230,229,227);
  color: #3a3535;
}

body {
  display: grid;
  padding: 3vmin;
  background: rgb(230,229,227);
}
.title {
	padding-left: 1em;
	grid-column: 1/-1;
	grid-row: 1;
	font-family: "Prata", serif;
  font-size: 6em;
  line-height: 1em;
  width: 100%;
  z-index: 2;
}

/* .title {
  padding-left: 1em;
  grid-column: 1/-1;
  grid-row: 1;
  font-family: "Prata", serif;
  font-size: 6em;
  width: 100%;
  z-index: 2;
} */
.mozart{
	font-size: 36px;
}
.title > .title-inner {
  display: inline-block;
}

@-webkit-keyframes text-clip {
  from {
    -webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes text-clip {
  from {
    -webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@-webkit-keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}
@keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes inner-left {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: none;
  }
}
@keyframes inner-left {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: none;
  }
}
.cafe,
.mozart {
  -webkit-animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
          animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.title-inner {
  display: inline-block;
  -webkit-animation: inner-left 1s 1s ease both;
          animation: inner-left 1s 1s ease both;
}

.cafe-inner {
  display: inline-block;
  -webkit-animation: inner-left 1s 1s ease both, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
          animation: inner-left 1s 1s ease both, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.mozart-inner {
  -webkit-animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
          animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.title {
  -webkit-animation: outer-left 1s 1s ease both;
          animation: outer-left 1s 1s ease both;
}

.cafe > .cafe-inner {
  display: inline-block;
}

.mozart {
  display: inline-block;
}

.image {
  grid-row: 1;
  grid-column: 2;
  margin-left: -2rem;
  opacity: 0.7;
  -webkit-animation: image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 2s backwards;
          animation: image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 2s backwards;
}
@-webkit-keyframes image-in {
  from {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@keyframes image-in {
  from {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
.image img {
  display: block;
  width: 70%;
  height: 80%;
  margin: auto;
}


@media only screen and (max-width: 760px) {
	.title {
		
		font-family: "Prata", serif;
	  font-size: 2em;
	  text-align: center;
	  padding-left: 0;
	  
	}
	.mozart{
		font-size: 18px;
		text-align: center;
	}
	.image{
		grid-row: 2;
		grid-column: 1/-1;
		margin: 1vw;
	}
}
